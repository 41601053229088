body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h6 {
  color: #9966ff;
}

h6 .mint {
  color: #ff66ff;
}

#root .diagramDescription {
  font-size:smaller;
  font-weight:light;
}